<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.pagamento', 1)}}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <card-info :campos="listaCampos()" :objeto="formatarObjeto()" />
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import CardInfo from '../../../shared-components/CardInfo';
import { convertToMoney } from '../../../common/functions/helpers';

export default {
  props: {
    acao: Object,
    configuracao: Object,
  },
  components: {
    CardInfo,
  },
  data() {
    return {
      camposPadrao: [
        { campo: 'justificativa', label: this.$tc('label.justificativa', 1), nome_campo: 'justificativa' },
      ],
      camposPadraoLiquidacao: [
        { campo: 'numero_documento', label: this.$tc('label.numero_documento', 1), nome_campo: 'numeroDocumento' },
        { campo: 'data_liquidacao', label: this.$tc('label.data_liquidacao', 1), nome_campo: 'dataLiquidacao' },
      ],
      camposLiquidacaoManual: [
        { campo: 'nota_fiscal', label: this.$tc('label.nota_fiscal', 1), nome_campo: 'notaFiscal' },
        { campo: 'nota_fiscal_envio_recebimento', label: this.$tc('label.nota_fiscal_envio_recebimento', 1), nome_campo: 'dataNfRecebimento' },
        { campo: 'nota_fiscal_vencimento', label: this.$tc('label.nota_fiscal_vencimento', 1), nome_campo: 'dataNfVencimento' },
        { campo: 'service', label: this.$tc('label.service', 1), nome_campo: 'service' },
        { campo: 'nota_fiscal_chave_cobertura', label: this.$tc('label.nota_fiscal_chave_cobertura', 1), nome_campo: 'nfChaveCobertura' },
        { campo: 'status_interno', label: this.$tc('label.status_interno', 1), nome_campo: 'statusInterno' },
        { campo: 'status_observacao', label: this.$tc('label.status_observacao', 1), nome_campo: 'observacaoStatus' },
        { campo: 'evidencia', label: this.$tc('label.evidencia_apuracao', 1), nome_campo: 'evidencia' },
        { campo: 'status_pagamento', label: this.$tc('label.status_pagamento', 1), nome_campo: 'statusPagamento' },
        { campo: 'valor_acao', label: this.$tc('label.valor_acao', 1), nome_campo: 'valorAcao' },
        { campo: 'total_pagamento', label: this.$tc('label.total_pagamento', 2), nome_campo: 'totalPagamentos' },
        { campo: 'pendente_pagamento', label: this.$tc('label.pendente_pagamento', 1), nome_campo: 'pendentePagamento' },
        { campo: 'valor_pagamento', label: this.$tc('label.valor_pagamento', 1), nome_campo: 'valorPagamento' },
      ],
    };
  },
  computed: {
  },
  methods: {
    listaCampos() {
      const campos = [].concat(this.camposPadrao);
      if (this.configuracao.passo3) {
        const { listaPadraoLiquidacao, listaLiquidacaoManual } = this.configuracao.passo3;

        if (!listaPadraoLiquidacao && !listaLiquidacaoManual) {
          return campos;
        }
        if (listaPadraoLiquidacao && listaPadraoLiquidacao.length) {
          const camposPadraoHabilitados = listaPadraoLiquidacao.filter((c) => c.fluxos && c.fluxos.includes('LIQUIDACAO'));
          camposPadraoHabilitados.forEach((a) => campos.push({
            campo: this.montaNomeCampo(this.camposPadraoLiquidacao, a.label),
            label: this.$tc('label.'.concat(a.label), 1),
          }));
        }
        if (listaLiquidacaoManual && listaLiquidacaoManual.length) {
          const camposLiqManualHabilitados = listaLiquidacaoManual.filter((c) => c.fluxos && c.fluxos.includes('LIQUIDACAO'));
          const listaLiquidacaoManualOrdenacao = this.configuracao.passo7.listaLiquidacaoManual;
          listaLiquidacaoManualOrdenacao.forEach((itemOrdenacao) => {
            camposLiqManualHabilitados.forEach((itemCampo) => {
              if (itemCampo.label === itemOrdenacao.label) {
                campos.push({
                  campo: this.montaNomeCampo(this.camposLiquidacaoManual, itemCampo.label),
                  label: this.$tc('label.'.concat(itemCampo.label), 1),
                });
              }
            });
          });
        }
      }
      return campos;
    },
    montaNomeCampo(lista, campo) {
      const nomeCampo = lista.filter((item) => item.campo === campo)[0].nome_campo;
      if (nomeCampo) {
        return nomeCampo;
      }
      return null;
    },
    formatarObjeto() {
      const acaoFormatada = { ...this.acao };
      if (this.acao && this.acao.valorAcao) {
        acaoFormatada.valorAcao = convertToMoney(this.acao.valorAcao);
      }
      if (this.acao && this.acao.valorPagamento) {
        acaoFormatada.valorPagamento = convertToMoney(this.acao.valorPagamento);
      }
      if (this.acao && this.acao.statusPagamento) {
        acaoFormatada.statusPagamento = this.$tc(`status_entidade.${this.acao.statusPagamento.toLowerCase()}`, 1);
      }
      if (this.acao && this.acao.statusInterno) {
        acaoFormatada.statusInterno = this.$tc(`status_apuracao_pagamento.${this.acao.statusInterno}`, 1);
      }
      return acaoFormatada;
    },
  },
};
</script>
<style>
  .select--sem--linha > .v-input__control > .v-input__slot:before {
    width: 0% !important;
  }
  .select--sem--linha > .v-input__control > .v-input__slot:after {
    border-style: none;
  }
</style>
