<template>
  <v-expansion-panel>
    <v-expansion-panel-header class="headline">
      <div>
        <span>{{ $tc('label.evidencias', 1)}}</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <list-files
        ref="listFiles"
        :uri="uri"
        v-if="acao.idPagamento"></list-files>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import basePath from '../../../common/functions/api-resource';
import ListFiles from '../../../shared-components/files/ListFiles';

export default {
  name: 'PagamentoArquivos',
  props: {
    acao: Object,
  },
  data() {
    return {
      esperar: false,
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
      },
    };
  },
  components: {
    ListFiles,
  },
  computed: {
    ...generateComputed('CTRT_PGTO', [
      'canEdit',
    ]),
    uri() {
      const urlUpload = basePath('planejamento_acao', 'arquivo/acao');
      return `${urlUpload}/${this.acao.idAcao}/apuracao/${this.acao.idApuracao}/pagamento/${this.acao.idPagamento}`;
    },
    habilitarUpload() {
      return false;
    },
  },
  methods: {
  },
  watch: {
    acao() {
      if (this.$refs.listFiles) {
        if (!this.esperar) {
          this.esperar = true;
          setTimeout(() => {
            this.$refs.listFiles.refresh();
            this.esperar = false;
          }, 2E2);
        }
      }
    },
  },
};
</script>
